@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  background: rgb(245, 245, 245);
  font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100%;
}
#root,
.router,
.router > * {
  display: flex;
  flex-direction: column;
  flex: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #f0843e;
}

/* special styles for injected html on Texts page */
.insertedHtml ul,
.insertedHtml ol {
  padding-inline-start: 1.5rem;
}
.insertedHtml a {
  color: #f0843e;
}

/* special styles for google maps api*/

.pac-container {
  z-index: 2000 !important;
}
